export const locale = {
  lang: 'th',
  data: {
    ACTION: {
      CLEAR: 'ล้างการค้นหา',
    },
    VALIDATION: {
      REQUIRED: 'กรุณาระบุข้อมูล',
      DUPLICATE: 'ข้อมูลซ้ำ',
      CHARACTER_LENGTH: 'จำนวนตัวอักษร',
      MIN: 'น้อยสุด',
      MAX: 'มากสุด',
      EMAIL: 'รูปแบบอีเมลไม่ถูกต้อง',
      MUST_BE_BETWEEN_X_CHARACTERS: 'จำนวนตัวอักษรระหว่าง {{min}}-{{max}} ตัว',
      MUST_BE_BETWEEN_8_16_CHARACTERS: 'ต้องมีความยาวระหว่าง 8-16 ตัวอักษร',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE: 'ต้องมีอักขระตัวพิมพ์เล็กอย่างน้อย {{value}} ตัว (A-Z)',
      MUST_CONTAIN_AT_LEAST_1_LETTER_IN_LOWER_CASE: 'ต้องมีอักขระตัวพิมพ์เล็กอย่างน้อย 1 ตัว (A-Z)',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE: 'ต้องมีอักขระตัวพิมพ์ใหญ่อย่างน้อย {{value}} ตัว (A-Z)',
      MUST_CONTAIN_AT_LEAST_1_LETTER_IN_UPPER_CASE: 'ต้องมีอักขระตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว (A-Z)',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'ต้องมีตัวเลขอย่างน้อย {{value}} หลัก (0-9)',
      MUST_CONTAIN_AT_LEAST_1_NUMBER: 'ต้องมีตัวเลขอย่างน้อย 1 หลัก (0-9)',
      MUST_CONTAIN_AT_LEAST_X_SPECIAL_CHARACTERS: 'ต้องมีอักขระพิเศษอย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_1_SPECIAL_CHARACTERS: 'ต้องมีอักขระพิเศษอย่างน้อย 1 ตัว',
      PASSWORD_MISMATCH: 'รหัสผ่านไม่ตรงกัน',
      PATTERN_INVALID: 'รูปแบบรหัสไม่ถูกต้อง',
      MUST_REQUIRED: 'Required',
      MUST_CHARACTER_LENGTH: 'จำกัดจำนวนตัวอักษร',
      MIN_CHARACTER_LENGTH: 'จำนวนตัวอักษรน้อยสุด',
      MAX_CHARACTER_LENGTH: 'จำนวนตัวอักษรมากสุด',
      MUST_VALUE_LIMIT: 'จำกัดข้อมูล',
      MIN_VALUE: 'ค่าข้อมูล (Min)',
      MAX_VALUE: 'ค่าข้อมูล (Max)',
      MUST_EMAIL_FORMAT: 'รูปแบบอีเมล',
      MUST_ID_CARD_FORMAT: 'รูปแบบบัตรประชาชน',
      MUST_PASSPORT_FORMAT: 'รูปแบบหนังสือเดินทาง',
      MUST_PHONE_FORMAT: 'รูปแบบเบอร์โทรศัพท์',
      MUST_MAC_ADDRESS_FORMAT: 'รูปแบบ Mac Address',
      MUST_LATITUDE_FORMAT: 'รูปแบบละติจูด',
      MUST_LONGTITUDE_FORMAT: 'รูปแบบลองจิจูด',
      MUST_URL_FORMAT: 'รูปแบบ URL',
      MUST_IP_ADDRESS_FORMAT: 'รูปแบบ IP address',
      MUST_GUID_FORMAT: 'รูปแบบ GUID',
      MUST_VIN_NUMBER_FORMAT: 'รูปแบบ VIN Number',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'ยืนยัน',
      CONFIRMATION_TEXT: 'คุณต้องการบันทึกข้อมูลนี้?',
      CONFIRMATION_DELETE_TITLE: 'ยืนยันการลบ',
      CONFIRMATION_DELETE_TEXT: 'คุณแน่ใจหรือว่าต้องการลบสิ้งนี้?',
      SUCCESS_TITLE: 'สำเร็จ!',
      SUCCESS_TEXT: 'บันทึกข้อมูลเรียบร้อยแล้ว',
      ERROR_TITLE: 'ไม่สำเร็จ',
      ERROR_TEXT: 'ระบบไม่สามารถดำเนินการได้ในขณะนี้ <br>กรุณาลองใหม่อีกครั้ง',
      WARNING_TITLE: 'แจ้งเตือน',
      WARNING_TEXT: 'มีบางอย่างไม่ถูกต้อง อาจทำให้คุณไม่สามารถดำเนินการต่อได้',
      LOGOUT_TITLE: 'ออกจากระบบ',
      LOGOUT_TEXT: 'คุณต้องการที่จะออกจากระบบใช่หรือไม่?',
      LOGIN_SUCCESS: 'เข้าสู่ระบบสำเร็จ',
      LOGIN_SUCCESS_TEXT: 'ระบบกำลังพาคุณไปยังไปหน้าหลัก...',
      PLEASE_WAIT: 'กรุณารอสักครู่',
      LOADING: 'กำลังโหลด...',
      //
      CONFIRM: 'ยืนยัน',
      CANCEL: 'ยกเลิก',
      CLOSE: 'ปิด',
    },
  },
};
