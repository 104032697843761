import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../api/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  apiUrl = '';

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  getApiUrl() {
    if (this.apiUrl) {
      return this.apiUrl;
    }
    this.apiUrl = environment.apiUrl;
    return this.apiUrl;
  }

  addToken(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      return req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    } else {
      return req.clone({});
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.url.includes('assets/')) {
      return next.handle(req);
    }

    const url = this.getApiUrl();
    const reqUrl = url + req.url;

    req = req.clone({
      url: reqUrl,
      // setHeaders: {
      //   'Cache-Control': 'no-cache, no-store, must-revalidate',
      // },
    });
    return next.handle(this.addToken(req, this.authService.getToken())).pipe(
      catchError((err: HttpErrorResponse, caught: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.authService.logout();
            this.router.navigateByUrl('/auth');
          }
          return throwError(() => new Error(JSON.stringify(err.error)));
          return caught;
        }
      }),
    );
  }
}
