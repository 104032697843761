import { Routes } from '@angular/router';
import { AuthGuardService } from './services/_guards/auth-guard.service';

export const routes: Routes = [
  {
    path: 'example',
    loadComponent: async () => import('./example/example.component').then((m) => m.ExampleComponent),
  },
  {
    path: 'auth',
    loadChildren: async () => import('./pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    loadChildren: async () => import('./pages/pages.module').then((m) => m.PagesModule),
  },

  // {
  //   path: '',
  //   redirectTo: '/example',
  //   pathMatch: 'full',
  // },
];
