import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../api/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(public router: Router, private authService: AuthService) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/auth']);
    return false;
  }
}
