export const locale = {
  lang: 'en',
  data: {
    ACTION: {
      CLEAR: 'Clear',
    },
    VALIDATION: {
      REQUIRED: 'Required',
      DUPLICATE: 'Duplicate',
      CHARACTER_LENGTH: 'Character Length',
      MIN: 'Min',
      MAX: 'Max',
      EMAIL: 'Email invalid',
      MUST_BE_BETWEEN_X_CHARACTERS: 'Must be between {{min}}-{{max}} characters',
      MUST_BE_BETWEEN_8_16_CHARACTERS: 'Must be between {{min}}-{{max}} characters',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE: 'Must contain at least {{value}} letter in lower case',
      MUST_CONTAIN_AT_LEAST_1_LETTER_IN_LOWER_CASE: 'Must contain at least 1 letter in lower case',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE: 'Must contain at least {{value}} letter in upper case',
      MUST_CONTAIN_AT_LEAST_1_LETTER_IN_UPPER_CASE: 'Must contain at least 1 letter in upper case',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'Must contain at least {{value}} number',
      MUST_CONTAIN_AT_LEAST_1_NUMBER: 'Must contain at least 1 number',
      MUST_CONTAIN_AT_LEAST_X_SPECIAL_CHARACTERS: 'Must contain at least {{value}} special characters',
      MUST_CONTAIN_AT_LEAST_1_SPECIAL_CHARACTERS: 'Must contain at least 1 special characters',
      PASSWORD_MISMATCH: "Those passwords didn't match",
      PATTERN_INVALID: 'Pattern is invalid',
      MUST_REQUIRED: 'Required',
      MUST_CHARACTER_LENGTH: 'Limit Character Length',
      MIN_CHARACTER_LENGTH: 'Min Character Length',
      MAX_CHARACTER_LENGTH: 'Max Character Length',
      MUST_VALUE_LIMIT: 'Value Limit',
      MIN_VALUE: 'Value (Min)',
      MAX_VALUE: 'Value (Max)',
      MUST_EMAIL_FORMAT: 'Email Format',
      MUST_ID_CARD_FORMAT: 'ID Card Format',
      MUST_PASSPORT_FORMAT: 'Passport Format',
      MUST_PHONE_FORMAT: 'Phone Format',
      MUST_MAC_ADDRESS_FORMAT: 'Mac Address Format',
      MUST_LATITUDE_FORMAT: 'Latitude Format',
      MUST_LONGTITUDE_FORMAT: 'Longitude Format',
      MUST_URL_FORMAT: 'URL Format',
      MUST_IP_ADDRESS_FORMAT: 'IP Address Format',
      MUST_GUID_FORMAT: 'GUID Format',
      MUST_VIN_NUMBER_FORMAT: 'VIN Number Format',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'Confirmation',
      CONFIRMATION_TEXT: 'Are you sure you want to proceed this?',
      CONFIRMATION_DELETE_TITLE: 'Delete',
      CONFIRMATION_DELETE_TEXT: 'Are you sure you want to delete this?',
      CONFIRMATION_DELETE_FILE: 'Are you sure you want to delete this file?',
      CONFIRMATION_HARD_DELETE_TEXT: 'Are you sure to delete this from LGS? This action cannot be undone.',
      ENTER_COMFIRM_TEXT: 'Enter the following to confirm:',
      ACTIVATE_EMAIL_HAS_SEND: 'An Email has been sent containing a link to activate your account.',
      SUCCESS_TITLE: 'Success!',
      SUCCESS_TEXT: 'Your progress has been saved.',
      ERROR_TITLE: 'Error',
      ERROR_TEXT: 'Sorry, something went wrong.',
      WARNING_TITLE: 'Warning',
      WARNING_TEXT: 'Something is not right, It may prevent you from continuing.',
      LOADING: 'Loading...',

      CONFIRM: 'Confirm',
      CANCEL: 'Cancel',
      CLOSE: 'Close',
      DATE_RANGE_INVALID: 'Start Date - End Date Invalid',
    },
  },
};
