import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { CoreComponentsModule } from './core/components/components.module';
import { CoreDialogsService } from './core/dialogs/dialogs.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { locale as langEN } from './core/i18n/en';
import { locale as langTH } from './core/i18n/th';
import { IStaticMethods } from 'preline/preline';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CoreComponentsModule, TranslateModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    private viewContainer: ViewContainerRef,
    private dialog: CoreDialogsService,
    private translateService: TranslateService,
    private router: Router,
  ) {
    this.dialog.init(this.viewContainer);
  }

  ngOnInit(): void {
    initFlowbite();
    this.initTranslate();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          try {
            window.HSStaticMethods.autoInit();
          } catch (error) {}
        }, 100);
      }
    });
  }

  initTranslate() {
    this.translateService.addLangs(['en', 'th']);
    this.translateService.setTranslation(langEN.lang, langEN.data, true);
    this.translateService.setTranslation(langTH.lang, langTH.data, true);
  }
}
